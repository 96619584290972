<template>
  <Header/>
  <div class="route" id="main">
    <router-view/>
  </div>
</template>
<script lang="ts">
import Header from '@/components/Header.vue'
export default {
  components:{
    Header
  }
}
</script>
<style lang="less" scoped>
.route{
  padding-top: 48px;
}
</style>

