import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    meta: {
      footer: true
    },
    component: () => import('../views/Index.vue')
  },
  {
    path: '/cloud/alicloud',
    name: 'cloud-alicloud',
    meta: {
      title: '产品服务-阿里云产品'
    },
    component: () => import('../views/cloud/AliCloud.vue')
  },
  {
    path: '/cloud/alicloud/detail/:id',
    name: 'cloud-alicloud-detail',
    meta: {
      title: '阿里云产品'
    },
    component: () => import('../views/cloud/CloudDetail.vue')
  },
  {
    path: '/cloud/dingtalk',
    name: 'cloud-dingtalk',
    meta: {
      title: '产品服务-钉钉产品'
    },
    component: () => import('../views/cloud/DingTalk.vue')
  },
  {
    path: '/cloud/dingtalk/zy',
    name: 'cloud-dingtalk-zy',
    meta: {
      title: '产品服务-钉钉专业版'
    },
    component: () => import('../views/cloud/DingTalkZY.vue')
  },
  {
    path: '/cloud/dingtalk/zs',
    name: 'cloud-dingtalk-zs',
    meta: {
      title: '产品服务-钉钉专属版'
    },
    component: () => import('../views/cloud/DingTalkZS.vue')
  },
  {
    path: '/cloud/dingtalk/zy2',
    name: 'cloud-dingtalk-zy2',
    meta: {
      title: '产品服务-钉钉专有版'
    },
    component: () => import('../views/cloud/DingTalkZY2.vue')
  },
  {
    path: '/cloud/teambition',
    name: 'cloud-teambition',
    meta: {
      title: '产品服务-Teambition'
    },
    component: () => import('../views/cloud/Teambition.vue')
  },
  {
    path: '/solution/planning',
    name: 'solution-planning',
    meta: {
      title: '数智决策·云图'
    },
    component: () => import('../views/solution/Planning.vue')
  },
  {
    path: '/solution/synergy',
    name: 'solution-synergy',
    meta: {
      title: '数智协同·云钉'
    },
    component: () => import('../views/solution/Synergy.vue')
  },
  {
    path: '/solution/member',
    name: 'solution-member',
    meta: {
      title: '数智人资·云效'
    },
    component: () => import('../views/solution/Member.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们'
    },
    component: () => import('../views/About.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }

  }
})

function IsPc() {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
  }
  return flag;
}

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
  if (IsPc()) {
    location.href = "http://www.ssgctech.com";
  }
  if (to.meta.title) {
    document.title = String(to.meta.title);
  } else {
    document.title = '盛世高创企业官方网站-阿里云授权服务商-阿里云代理商-钉钉授权服务商-钉钉代理商-Teambition授权服务商-Teambition代理商'
  }
  next()
})


export default router
